
<div class="shadow p-3 m-5 rounded bg-white" style="height: 20rem;">
    <div class="d-flex justify-content-center">
        <div class="blueColor" style="height:80px; width:80px; border-radius: 50%;height: 5rem;">
            <img src="assets/eservice.png" class="h-100 w-100" alt="">
        </div>
    </div>
   
    <div class=" d-flex flex-column justify-content-around" style="height: 13rem;">
        <h6 class="msb blueColorT text-center"> {{data?.title}}</h6>
        <p class="text-justify" >{{data?.resume | slice:0:100}} ...</p>
        <div class="text-end ">
            <a *ngIf="data?.is_external_service" type="button" target="_blank" href="{{data?.link}}" class="blueColorT ">Faire une demande</a>
            <a *ngIf="!data?.is_external_service" type="button" routerLink="/e-services/{{data?.code}}" class="blueColorT ">Faire une demande</a>
        </div>
    </div>
</div>
<!-- <div class="shadow p-3 m-5 rounded" style="background-color: transparent;">
    <div class="row" style="height: 6rem;">
        <div class="col-12 col-md-4 d-flex ">
            <div class="blueColor align-self-center" style="height:100px; width:100px; border-radius: 50%">
                <img src="assets/eservice.png" class="h-100 w-100" alt="">
            </div>
        </div>
        <div class="col-12 col-md-8 d-flex flex-column justify-content-around">
           
        </div>
    </div>
</div> -->
