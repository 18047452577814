import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalService } from './storage_services/local.service';
import { Config } from '../../app.config';
import { Observable } from 'rxjs';
import { globalName } from '../_utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EserviceService {

  constructor(private http: HttpClient,private local_service:LocalService) {
    
  }

  store(resource:any){
    return this.http.post<any[]>(`${Config.toBoApiUrl()}/eservice`,resource, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

  getStatus(code:any){
    return this.http.get<any[]>(`${Config.toBoApiUrl()}/eservice-status/${code}`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

  getDetailsByToken(token:any){
    return this.http.get<any[]>(`${Config.toBoApiUrl()}/eservice-details-by-token/${token}`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }
  setProof(resource:any){
    return this.http.post<any[]>(`${Config.toBoApiUrl()}/eservice-set-proof`,resource, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

  getEntrepriseDetails(code:any){
    return this.http.get<any[]>(`${Config.toBoApiUrl()}/eservice-entreprise-details/${code}`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

  getAllDepart(){
    return this.http.get<any[]>(`${Config.toMatApiUrl()}/departement`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

  getAllCommune(idDepar){
    return this.http.get<any[]>(`${Config.toMatApiUrl()}/commune/${idDepar}`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }
  getTypeStructures(){
    return this.http.get<any[]>(`${Config.toMatApiUrl()}/typestructures`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }
  getNatureContracts(){
    return this.http.get<any[]>(`${Config.toMatApiUrl()}/naturecontracts`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }
  get(code:any,slug:any,prestation_code?:any): Observable<any> {
    return this.http.get<any>(`${Config.toBoApiUrl()}/requete/get-one-correction/${code}/${slug}?prestation_code=${prestation_code}`, Config.httpHeader(localStorage.getItem(globalName.token)));
  }
}
