export const VcaForm:any={
    code:"",
    has_account:"",
    type:"",
    identity_requester:"",
    email_requester:"",
    contact_requester:"",
    quality_requester:"",
    structure_name:"",
    structure_type:"",
    department:"",
    city:"",
    district:"",
    ifu:"",
    rccm:"",
    job:"",
    address:"",
    ifu_file:{
        file:"",
        name:"IFU",
        file64:"",
        isSetted:false

    },
    rccm_file:{
        file:"",
        name:"RCCM",
        file64:"",
        isSetted:false

    },
    identity_responsable:"",
    criminal_record:"",
    criminal_record_file:{
        file:"",
        name:"Casier judiciaire",
        file64:"",
        isSetted:false

    },
    doc_file:{
        file:"",
        name:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
        file64:"",
        isSetted:false

    },
    list:[]
}