import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbOffcanvasConfig, NgbOffcanvas, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IgxStepper, IStepChangingEventArgs } from 'igniteui-angular';
import { ToastrService } from 'ngx-toastr';
import { VcaForm } from '../../../../core/_forms/vca_form';
import { AppSweetAlert } from '../../../../core/_utils/app-sweet-alert';
import { FileService } from '../../../../core/_utils/file-service';
import { LocalStorageService } from '../../../../core/_utils/local-stoarge-service';
import { VcnForm } from '../../../../core/_forms/vcn_form';
import { EserviceService } from '../../../../core/_services/eservice.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vcn',
  templateUrl: './vcn.component.html',
  styleUrls: ['./vcn.component.css'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce,
       {
        // Set the duration to 5seconds and delay to 2seconds
        params: { timing: 2, delay: 1 }
      }))])
  ],
})
export class VCNComponent {
  myRequestForm=VcnForm
  ts:any[]=[];
  communes:any[]=[];
  departs:any[]=[];
  ncs:any[]=[];
  requiredFiles:any[]=[];
  settedFiles:any[]=[];
  imageSrc!: string | SafeResourceUrl | undefined ;
  fileInput:any
  last_step=-1;
  loading=false
  ay:any;
  oldSchoolCurrentIndex:any=0;
  elementCurrentIndex:any=0;
  system=""
  nationality="Béninoise"
  myfile:any={
    file:"",
    name:"Acte de naissance",
    file64:"",
    isSetted:false

  }
  
@Input('checkData')checkData:any;

@ViewChild("reqTop") reqTop!: ElementRef<HTMLElement>;
@ViewChild('stepper')stepper!:IgxStepper
@ViewChild('fileInp')fileInp!:ElementRef
bounce: any;
code:any
slug:any
prestationCode:any

  constructor(
    private eserviceService:EserviceService,
    private spinner: NgxSpinnerService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private _sanitizationService: DomSanitizer,
    configOffCanvas: NgbOffcanvasConfig,
     private offcanvasService: NgbOffcanvas,
     private toastrService: ToastrService,
     private lsService:LocalStorageService,
     config: NgbModalConfig,
    private modalService: NgbModal) {
      config.backdrop = 'static';
      config.keyboard = false;
      
    configOffCanvas.position = 'end';
		configOffCanvas.backdropClass = 'bg-dark';
		configOffCanvas.keyboard = false;
   }
  ngOnChanges(changes: SimpleChanges): void {
   console.log(changes)
   if (this.checkData !=undefined) {
    let el=JSON.parse(changes.checkData['currentValue'].details)
    this.myRequestForm.type=el.type
    this.myRequestForm.code=el.code
    this.myRequestForm.has_account=el.has_account
    this.myRequestForm.email_requester=el.email_requester
    this.myRequestForm.identity_requester=el.identity_requester
    this.myRequestForm.phone_requester=el.phone_requester
    this.myRequestForm.quality_requester=el.quality_requester
    this.myRequestForm.identity_respo=el.identity_respo
    this.myRequestForm.email_respo=el.email_respo
    this.myRequestForm.contact_respo=el.contact_respo
    this.myRequestForm.job_respo=el.job_respo
    this.myRequestForm.city_respo=el.city_respo
    this.myRequestForm.department_respo=el.department_respo
    this.myRequestForm.municipality_respo=el.municipality_respo
    this.myRequestForm.name_structure=el.name_structure
    this.myRequestForm.type_structure=el.type_structure
    this.myRequestForm.type_structure_id=el.type_structure_id
    this.myRequestForm.email_structure=el.email_structure
    this.myRequestForm.phone_structure=el.phone_structure
    this.myRequestForm.city_structure=el.city_structure
    this.myRequestForm.district_structure=el.district_structure
    this.myRequestForm.department_structure=el.department_structure
    this.myRequestForm.municipality_structure=el.municipality_structure
    this.myRequestForm.ifu_file=el.ifu_file
    this.myRequestForm.rccm_file=el.rccm_file
    this.myRequestForm.doc_file=el.doc_file
    this.myRequestForm.npi_requester=el.npi_requester
    this.myRequestForm.npi_respo=el.npi_respo
    this.myRequestForm.ifu_structure=el.ifu_structure
    this.myRequestForm.requester_is_respo=el.requester_is_respo
    this.myRequestForm.list=el.list
    this.eserviceService.getAllCommune(this.myRequestForm.department_respo?.id).subscribe((res:any)=>{
      this.communes=res
    })
    this.toastrService.info('Veuillez procéder à la correction demandée','Identification')

    if (el.department_respo?.id!="") {
      this.eserviceService.getAllCommune(el.department_respo?.id).subscribe((res:any)=>{
        this.communes=res
      })
    } else {
      this.eserviceService.getAllCommune(el.department_structure?.id).subscribe((res:any)=>{
        this.communes=res
      })
    }
    
   }
  }
  ngOnDestroy(): void {
  
  }
  ngAfterViewInit(){
    this.reqTop.nativeElement.scrollTop
    //this.reqTop.nativeElement.scrollIntoView()
  }

  ngOnInit(): void {

    this.code=this.activatedRoute.snapshot.paramMap.get('code')
    this.slug=this.activatedRoute.snapshot.paramMap.get('slug')
    this.prestationCode=this.activatedRoute.snapshot.paramMap.get('prestationCode')
    this.getNatureContracts()
    this.getDepartments()
    this.getTypeStructures()

  }

  getDepartments(){
    this.eserviceService.getAllDepart().subscribe((res:any)=>{
      this.departs=res
    })
  }

  getTypeStructures(){
    this.eserviceService.getTypeStructures().subscribe((res:any)=>{
      this.ts=res.data
    })
  }


  getCommunes(event:any){
    console.log(event)
    if (this.myRequestForm.type=="physique") {
      this.myRequestForm.department_respo=event
    }else{
      this.myRequestForm.department_structure=event
    }
    this.eserviceService.getAllCommune(event?.id).subscribe((res:any)=>{
      this.communes=res
    })
  }

  updateFormValue(event:any){
    if (this.myRequestForm.type=="physique") {
      this.myRequestForm.type_structure=event
    }else{
      this.myRequestForm.type_structure=event
    }
  }
  updateFormValue2(event:any){
    if (this.myRequestForm.type=="physique") {
      this.myRequestForm.type_structure=event
    }else{
      this.myRequestForm.type_structure=event
    }
  }
  getNatureContracts(){
    this.eserviceService.getNatureContracts().subscribe((res:any)=>{
      this.ncs=res.data
    })
  }

  async uploadIfu(event:any){
    if(event.target.files.length>0){
     this.fileInput=event.target.files[0]
     this.myRequestForm.doc_file.file=this.fileInput
      let arrInput=await FileService.readFileAsync(this.fileInput)
      this.myRequestForm.doc_file.file64=await FileService.fromArrayBufferToBase64(arrInput)
      this.myRequestForm.doc_file.isSetted=true
    }
  } 
    async uploadRccm(event:any){
    if(event.target.files.length>0){
     this.fileInput=event.target.files[0]
     this.myRequestForm.rccm_file.file=this.fileInput
      let arrInput=await FileService.readFileAsync(this.fileInput)
      this.myRequestForm.rccm_file.file64=await FileService.fromArrayBufferToBase64(arrInput)
      this.myRequestForm.rccm_file.isSetted=true
    }
  } 

  store(myForm:NgForm){   
    let files:any[]=[]
    let formData= new FormData()

    if (this.myRequestForm.doc_file.file64!= "") {
      files.push({
        name:this.myRequestForm.doc_file.name,
        file64:this.myRequestForm.doc_file.file64
      })
    }

    

    formData.append('infos',JSON.stringify(this.myRequestForm))
    formData.append('from_pns',"0")
    if (this.checkData==undefined) {
      formData.append('code',this.code)
      formData.append('reqCode',null)
    } else {
      formData.append('code',this.prestationCode)
      formData.append('reqCode',this.checkData.code)
    }
   
    formData.append('myFiles',JSON.stringify(files))

    this.loading=true
      this.eserviceService.store(formData).subscribe((res:any)=>{
        this.loading=false

        this.toastrService.success(res.message)
        this.reqTop.nativeElement.scrollTop
        this.reInit()
        myForm.resetForm()
        this.stepper.navigateTo(0)
      },(res:any)=>{
        this.loading=false
      })
  }


  trackByFn(index:any, item:any) {
    console.log(index,item)
    return item.isSetted; 
  }
  



  
  open(content:any, file:any) {
    //console.log(this.myRequestForm.doc_file)
    this.readURL(file)
		this.offcanvasService.open(content);
	}
  readURL(file: File): void {
    // this.imageSrc=this._sanitizationService.bypassSecurityTrustResourceUrl(file)
    console.log(file)
    const reader = new FileReader();
    reader.onload = e =>{
     let src = reader.result as string
      this.imageSrc=this._sanitizationService.bypassSecurityTrustResourceUrl(src)

    };
    reader.readAsDataURL(file);

}

removeFile(i:any){
  //this.fileInputs.splice(i,1)
  AppSweetAlert.confirmBox("error","Document joint","Voulez vous retier cette pièce ?").then((result:any)=>{
    if (result.isConfirmed) {
      this.requiredFiles[i].file="",
      this.requiredFiles[i].isSetted=false
    }
  })
 
}


  next(){
    this.stepper.activeStepChanged.subscribe((res:any)=>{
        this.last_step=res.newIndex
        console.log(res)
      if (res.newIndex >  res.oldIndex) {
       
        switch (res.oldIndex) {

        case 0:
            this.verifyStepper1(res)
          break;
          case 1:
            this.verifyStepper5(res)
          break;
        case 2:
          this.myRequestForm.type=="physique"?this.verifyStepper2(res):this.verifyStepper4(res)
          break;
          case 3:
            this.verifyStepper3(res)
          break;
      
        default:
          this.stepper.navigateTo(0)
          break;
      }
    } 
    })
    this.stepper.next()
    this.reqTop.nativeElement.scrollIntoView()
  }

  prev(){

    this.stepper.prev()
  }

  verifyStepper1(res:any){
    if (this.myRequestForm.has_account=="1") {
      if(this.myRequestForm.code==""){
          res.cancel=true
          this.toastrService.warning('Veuillez insérer un code ','Type de demandeur')
         }else{
          res.cancel=false
    
        }
    } else {
      if( this.myRequestForm.type==""){
          res.cancel=true
          this.toastrService.warning('Veuillez choisir un type','Type de demandeur')
         }else{
          res.cancel=false
    
        }
    }
  
  }
  verifyStepper2(res:any){
    if(
      (this.myRequestForm.has_account==1 && this.myRequestForm.code =="")
      ||
      (this.myRequestForm.has_account==0 && 
        (    
        this.myRequestForm.identity_respo=="" ||
        this.myRequestForm.phone_respo=="" ||
        this.myRequestForm.email_respo=="" ||
        this.myRequestForm.department_respo?.id=="" ||
        this.myRequestForm.municipality_respo_id=="" ||
        this.myRequestForm.city_respo=="" ||
        this.myRequestForm.doc_file=="" 
        )
        )){
        res.cancel=true
        this.toastrService.warning('Veuillez remplir tous les champes requis','Informations sur le soumissionnaire')
    }else{
      if (!this.isValidEmail(this.myRequestForm.email_respo)) {
            res.cancel=true
            this.toastrService.warning('Veuillez insérer un email valide','Email validation')
      
          }else{
            res.cancel=false
      
          }
    }
  }
  verifyStepper3(res:any){
    if(
      this.myRequestForm.list.length==0
      ){
        res.cancel=true
        this.toastrService.warning('Veuillez charger au moins un élément','Informations sur la structure')
    }else{
      res.cancel=false
    }
   
  }
  verifyStepper4(res:any){
    console.log(this.myRequestForm)
    if(
    this.myRequestForm.name_structure=="" ||
    this.myRequestForm.type_structure_id=="" ||
    this.myRequestForm.department_structure?.id=="" ||
    this.myRequestForm.municipality_structure_id=="" ||
    this.myRequestForm.phone_structure=="" ||
    this.myRequestForm.email_structure=="" ||
    this.myRequestForm.city_structure=="" ||
    this.myRequestForm.district_structure=="" ||
    this.myRequestForm.doc_file=="" 
      ){
        res.cancel=true
        this.toastrService.warning('Veuillez remplir tous les champes requis','Nombre de contrats à soumettre pour visa')
    }else{
      if (!this.isValidEmail(this.myRequestForm.email_structure)) {
        res.cancel=true
        this.toastrService.warning('Veuillez insérer un email valide','Email validation')
  
      }else{
        res.cancel=false
  
      }    }
}
verifyStepper5(res:any){
  if(
  this.myRequestForm.identity_requester=="" ||
  this.myRequestForm.phone_requester=="" ||
  this.myRequestForm.email_requester=="" ||
  this.myRequestForm.quality_requester==""
    ){
      res.cancel=true
      this.toastrService.warning('Veuillez remplir tous les champes requis','Informations sur le soumissionnaire')
  }else{
    if (!this.isValidEmail(this.myRequestForm.email_requester)) {
          res.cancel=true
          this.toastrService.warning('Veuillez insérer un email valide','Email validation')
    
        }else{
          if (this.myRequestForm.requester_is_respo) {
            this.myRequestForm.identity_respo=this.myRequestForm.identity_requester
            this.myRequestForm.job_respo=this.myRequestForm.quality_requester
            this.myRequestForm.email_respo=this.myRequestForm.email_requester
            this.myRequestForm.phone_respo=this.myRequestForm.phone_requester
          }else{
            this.myRequestForm.identity_respo=""
            this.myRequestForm.job_respo=""
            this.myRequestForm.email_respo=""
            this.myRequestForm.phone_respo=""
          }
          res.cancel=false
    
        }
  }
}

getEntrepriseDetails(){
  this.spinner.show();

  this.myRequestForm.code=this.myRequestForm.type=="moral"?this.myRequestForm.ifu_structure:this.myRequestForm.npi_requester
  this.eserviceService.getEntrepriseDetails(this.myRequestForm.code).subscribe((res:any)=>{
    if (res.success) {
      let el=JSON.parse(res.data.details)
      console.log(el)
      this.myRequestForm.type=el.type
      this.myRequestForm.code=el.code
      this.myRequestForm.has_account=el.has_account
      this.myRequestForm.email_requester=el.email_requester
      this.myRequestForm.identity_requester=el.identity_requester
      this.myRequestForm.phone_requester=el.phone_requester
      this.myRequestForm.quality_requester=el.quality_requester
      this.myRequestForm.identity_respo=el.identity_respo
      this.myRequestForm.email_respo=el.email_respo
      this.myRequestForm.contact_respo=el.contact_respo
      this.myRequestForm.job_respo=el.job_respo
      this.myRequestForm.city_respo=el.city_respo
      this.myRequestForm.department_respo=el.department_respo
      this.myRequestForm.municipality_respo=el.municipality_respo
      this.myRequestForm.name_structure=el.name_structure
      this.myRequestForm.type_structure=el.type_structure
      this.myRequestForm.type_structure_id=el.type_structure_id
      this.myRequestForm.email_structure=el.email_structure
      this.myRequestForm.phone_structure=el.phone_structure
      this.myRequestForm.city_structure=el.city_structure
      this.myRequestForm.district_structure=el.district_structure
      this.myRequestForm.department_structure=el.department_structure
      this.myRequestForm.municipality_structure=el.municipality_structure
      this.myRequestForm.ifu_file=el.ifu_file
      this.myRequestForm.rccm_file=el.rccm_file
      this.myRequestForm.doc_file=el.doc_file
      this.myRequestForm.npi_requester=el.npi_requester
      this.myRequestForm.npi_respo=el.npi_respo
      this.myRequestForm.ifu_structure=el.ifu_structure
      this.myRequestForm.requester_is_respo=el.requester_is_respo
      this.eserviceService.getAllCommune(this.myRequestForm.department_respo?.id).subscribe((res:any)=>{
        this.communes=res
      })
      this.toastrService.success('Bienvenue cher usager','Identification')

      if (el.department_respo?.id!="") {
        this.eserviceService.getAllCommune(el.department_respo?.id).subscribe((res:any)=>{
          this.communes=res
        })
      } else {
        this.eserviceService.getAllCommune(el.department_structure?.id).subscribe((res:any)=>{
          this.communes=res
        })
      }
      

      console.log(res.data)
      this.stepper.navigateTo(0)

    } else {

      this.toastrService.warning('Aucune donnée trouvée avec ce code','Identification')

    }

    this.spinner.hide();
    this.stepper.next();
  },(err:any)=>{
    this.spinner.hide();

    this.toastrService.warning(err.error,'Identification promoteur')

  })

}

  public handleActiveStepChanging(event: IStepChangingEventArgs) {
    console.log("ici")
    if (event.newIndex >  event.oldIndex) {

    switch (event.oldIndex) {

      case 0:
          this.verifyStepper1(event)
        break;
        case 1:
          this.verifyStepper5(event)
        break;
      case 2:
        this.myRequestForm.type=="physique"?this.verifyStepper2(event):this.verifyStepper4(event)
        break;
      case 3:
          this.verifyStepper3(event)
        break;
    
      default:
        this.stepper.navigateTo(0)
        break;
    }
  }
   }

 


  filterByType(type:any){
    return this.requiredFiles.filter((el:any) => el.type==type && el.isRequired == true)
  }

  verifiedFiles(type:any){
    let files= this.filterByType(type)
    let notSetted= files.filter((el:any)=>el.isSetted==false);

    return notSetted.length >0 ? false:true
  }

  checkControl(){
    //this.dropdownList=this.dropdownList2
  }


  public isValidEmail(emailString: string): boolean {
    try {
      let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
      let valid = pattern.test(emailString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }

  openOldSchoolEdition(content:any,i:any){
    this.oldSchoolCurrentIndex=i
    this.modalService.open(content)
  }
  removeItem(i:any){
    this.myRequestForm.old_schools.splice(i,1)
  }

  addElement(form:NgForm){
   

    this.myRequestForm.list.push({
      npi:form.value.npi,
      identity:form.value.identity,
      nationality:form.value.nationality,
      speciality:form.value.speciality,
      job:form.value.job,
      nature:form.value.nature,
      file:this.myfile,
      is_valid:null,
      proof:null,
      has_proof:false
    })
    console.log(this.myRequestForm.list)
    this.modalService.dismissAll()
    this.reInitFiles()
    form.resetForm()
    this.nationality="Béninoise"
  }
  editElement(){
    this.modalService.dismissAll()
  }
 

  openEdition(content:any,i:any){
    this.elementCurrentIndex=i
    this.modalService.open(content)
  }
  removeItem2(i:any){
    this.myRequestForm.list.splice(i,1)
  }



  add(content:any){
    this.modalService.open(content,{size:'lg'});
  }

  convertTobase64(){
    this.settedFiles.forEach(async (el:any) => {
      el.fileArrayBuffer=await FileService.fromArrayBufferToBase64(await FileService.readFileAsync(el.file)) 
    })
  }

  changeIfu(){
    this.myRequestForm.doc_file.isSetted=false
  }

  changeRccm(){
    this.myRequestForm.rccm_file.isSetted=false
  }

  changeCR(){
    this.myRequestForm.criminal_record_file.isSetted=false
  }

  async uploadAtachementFile(event:any, index:any){
    if(event.target.files.length>0){
      this.fileInput=event.target.files[0]
      let arrInput=await FileService.readFileAsync(this.fileInput)
      this.myfile.name="Contrat"
      this.myfile.file=this.fileInput
      this.myfile.isSetted=true
      this.myfile.file64=await FileService.fromArrayBufferToBase64(arrInput)
     }
  }
  async uploadChangedAtachementFile(event:any, index:any){
    if(event.target.files.length>0){
      this.fileInput=event.target.files[0]
      let arrInput=await FileService.readFileAsync(this.fileInput)
      let current =this.myRequestForm.list[this.elementCurrentIndex].files[index];
         current.file64=await FileService.fromArrayBufferToBase64(arrInput)
         current.file=this.fileInput
         current.isSetted=true
         this.myRequestForm.list[this.elementCurrentIndex].files.slice(index,current)
     }
  }

  reInitFiles(){
    this.myfile={
      file:"",
      name:"Acte de naissance",
      file64:"",
      isSetted:false

  }
  }

  getNatureLabel(id:any){
   return  this.ncs.find((el:any) =>el.id ==id).name
  }
  getDepartmentLabel(id:any){
   return  this.departs.find((el:any) =>el.id ==id).libelle
  }
  getMunicipalityLabel(id:any){
   return  this.communes.find((el:any) =>el.id ==id).libellecom
  }
  getTypeStructureLable(id:any){
   return  this.ts.find((el:any) =>el.id ==id).name
  }

  reInit(){
    this.myRequestForm={
      code:"",
      has_account:"",
      type:"",
      identity_requester:"",
      email_requester:"",
      contact_requester:"",
      quality_requester:"",
  
      identity_respo:"",
      email_respo:"",
      contact_respo:"",
      job_respo:"",
      city_respo:"",
      department_respo:"",
      municipality_respo:"",
  
  
      name_structure:"",
      type_structure:"",
      type_structure_id:"",
      email_structure:"",
      contact_structure:"",
      city_structure:"",
      district_structure:"",
      department_structure:"",
      municipality_structure:"",
  
      ifu_file:{
          file:"",
          name:"IFU",
          file64:"",
          isSetted:false
  
      },
      rccm_file:{
          file:"",
          name:"RCCM",
          file64:"",
          isSetted:false
  
      },
      doc_file:{
          file:"",
          name:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
          file64:"",
          isSetted:false
  
      },
      list:[]
  }
  }
}
