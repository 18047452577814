import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-connaissance',
  templateUrl: './base-connaissance.component.html',
  styleUrls: ['./base-connaissance.component.css']
})
export class BaseConnaissanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
