import { Component, OnInit } from '@angular/core';
import { AlertNotif } from '../../alert';
import { clientData, globalName } from '../../core/_utils/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../core/_services/application.service';
import { AuthService } from '../../core/_services/auth.service';
import { IpServiceService } from '../../core/_services/ip-service.service';
import { PdaService } from '../../core/_services/pda.servic';
import { StatusService } from '../../core/_services/status.service';
import { LocalService } from '../../core/_services/storage_services/local.service';

@Component({
  selector: 'app-log-usager',
  templateUrl: './log-usager.component.html',
  styleUrls: ['./log-usager.component.css']
})
export class LogUsagerComponent implements OnInit {
  loading: boolean;
  visitor: boolean = false
  client_id: string;
  client_secret: string;
  needMailCheck: boolean = false
  email: string

  ipAddress: string
  constructor(private status_service:StatusService,private applicationService: ApplicationService, private user_auth_service: AuthService, private local_service: LocalService, private router: Router,
    private route: ActivatedRoute, private ip: IpServiceService, private pdaService: PdaService, private statusService:StatusService) { }

  ngOnInit(): void {
  }


  loginSend(value) {
    this.loading = true;
    if (this.visitor) {
        value['client_id'] = this.client_id;
        value['grant_type'] = clientData.grant_type;
        value['client_secret'] = this.client_secret;
    } else {
        value['client_id'] = clientData.client_id;
        value['grant_type'] = clientData.grant_type;
        value['client_secret'] = clientData.client_secret;
    }
    this.email = value['email'];
    value['ip'] = this.ipAddress;
    this.user_auth_service.login(value).subscribe(
        (res: any) => {
            if (res.check_code) {
                var data = res.params;
                data['client_id'] = value['client_id']
                data['grant_type'] = value['grant_type']
                data['client_secret'] = value['client_secret']
                data['user_id'] = res.user_id
                
                this.local_service.setItem(globalName.params, data)
                this.router.navigate(['/check-code']);
            } else {
                var url = "";

                this.user_auth_service.setUserLoggedIn(true);

                if (res.user.active) {
                    this.loading = false;
                    
                    console.log(res.user)
                    if (res.user.is_portal_admin == true) {
                        url = globalName.back_url + '?access_token=' + res.access_token + '&email=' + res.user.email;

                    } else {
                        this.local_service.setItem(globalName.token, res.access_token)
                        this.local_service.setItem(globalName.current_user, res.user)
                        this.local_service.setItem(globalName.refresh_token, res.refresh_token)
                        
                        url = res.redirect_url + '?access_token=' + res.access_token + '&email=' + res.user.email;

                    }
                    if (this.visitor || res.user.is_portal_admin == true) {
                        console.log(url)
                        window.location.href = url;
                    } else {
                        this.router.navigate(['/home']);
                    }
                } else {

                }
            }


        },
        (err) => {
            this.loading = false;
            console.log(err)
            if (err.error.error == "invalid_grant") {
                AlertNotif.finish("Connexion", "Identifiant ou mot de passe incorrect", "error")
            } else {
                AlertNotif.finish("Connexion", "Echec de connexion", "error")
            }
        }
    )

}

}
