import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EserviceService } from '../../core/_services/eservice.service';
import { AlertNotif } from '../../alert';

@Component({
  selector: 'app-eserive-status',
  templateUrl: './eserive-status.component.html',
  styleUrls: ['./eserive-status.component.css']
})
export class EseriveStatusComponent {
  private fragment: string | null="";
  code=""
  loading=false


  constructor(
    private route:ActivatedRoute,
    private eserviceService:EserviceService
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.code=this.route.snapshot.paramMap.get('code')??""
  }


  send(value:any){
    this.loading=true
    this.eserviceService.getStatus(this.code).subscribe((res:any)=>{
      this.loading=false
      AlertNotif.finish('Statut de demande',res.message,'info')

    },
    
    (res:any)=>{
      this.loading=false
    })
  }

}
