import { Component, OnInit } from '@angular/core';
import { PdaService } from '../../core/_services/pda.servic';

@Component({
  selector: 'app-ccsp',
  templateUrl: './ccsp.component.html',
  styleUrls: ['./ccsp.component.css']
})
export class CcspComponent implements OnInit {
  data:any[]=[]
  title = 'My first AGM project';
  lat = 51.678418;
  lng = 7.809007;
  
  constructor(private pdaService:PdaService) { }

  ngOnInit(): void {
    this.pdaService.getCcsps().subscribe((res:any)=>{
      this.data=res.data
    })
  }

}
