<div class="container m-5">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end my-2">
               <div>
                    <input type="text" placeholder="Rechercher..." class="form-control" name="" id="">
               </div>
        </div>
        <div class="col-md-12">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr class="blueColor text-white ">
                            <th>Préoccupations</th>
                            <th>Réponses</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr>
                                <td>Question</td>
                                <td>Reponse</td>
                                
                            </tr>
                            <tr>
                                <td>Question</td>
                                <td>Reponse</td>
                                
                            </tr>
                            <tr>
                                <td>Question</td>
                                <td>Reponse</td>
                                
                            </tr>
                            <tr>
                                <td>Question</td>
                                <td>Reponse</td>
                                
                            </tr>
                           
                    </tbody>
                </table>
            </div>
    </div>
</div>