import { Component, OnInit } from '@angular/core';
import { PdaService } from '../../core/_services/pda.servic';
import { NgwWowService } from 'ngx-wow';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-eservice',
  templateUrl: './eservice.component.html',
  styleUrls: ['./eservice.component.css']
})
export class EserviceComponent implements OnInit {
data:any[]=[]
private wowSubscription: Subscription;

  constructor(
    private router: Router, 
    private pdaService:PdaService,
    private wowService: NgwWowService) { 
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.wowService.init(); 
        }
     });
    }

  ngOnInit(): void {
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item:HTMLElement) => {
        // do whatever you want with revealed element
      });
    this.pdaService.getEservices().subscribe((res:any)=>{
      this.data=res.data
    })
  }


  ngOnDestroy() {
    this.wowSubscription.unsubscribe();
  }
}
