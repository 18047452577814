<div class="container " style="margin-top: 10vh; margin-bottom: 10vh">
       <div class="row">
            <div class="col-md-4  mx-auto">
                    <form #loginForm="ngForm" (ngSubmit)="forgotPassword(loginForm.value)" >
                            <div class="card" style="background-color: #FFF;box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px;
                                        ">
                                        <div class="card-header blueColor py-2">
                                                <div class="d-flex justify-content-center">
                                                        <img src="assets/auth-person.svg" height="30" class="mx-2" alt="">
                                                        <h6  class="text-center text-white mt-2">
                                                           
                                                            <strong class="mt-2">Mot de passe oublié</strong></h6>
                                                </div>
        
                                        </div>
                                <div class="card-body">
                                   <div class="d-flex justify-content-center">
                                        <small class="text-muted text-center my-2">Saisir votre email, cliquer sur envoyer puis consulter votre boite mail</small>
                                   </div>
                                    <div class="form-group">
                                        <label for="">Email</label>
                                        <input type="email" class="form-control" name="email" #email="ngModel" ngModel>
                                        <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                                    <small *ngIf="email.errors?.required" id="typeLabelHelp" class="form-text">Email requis</small>
                                                </span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button class="btn btn-warning btn-block" [disabled]="loginForm.invalid || loading">Envoyer</button>
                                    </div>
                                    <hr class="my-4">
                                    <!--div *ngIf="needMailCheck" class="d-flex justify-content-center">
                                             <a href="#" (click)="resendMailCheclCode()" class="text-secondary">Renvoyer le code de vérification</a>
                                    </div-->
                                    <div class="d-flex justify-content-center">
                                            <span class=" text-center mx-auto" style="font-size:12px">Retour à la  <a [routerLink]="['/main']" fragment="loginBlock" (click)="gotoHashtag('loginBlock')" class="text-secondary"><u>Connexion</u></a></span>

                                    </div>

                                </div>
                            </div>
                        </form>
            </div>
       </div>
    </div>
