import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prestation-item',
  templateUrl: './prestation-item.component.html',
  styleUrls: ['./prestation-item.component.css']
})
export class PrestationItemComponent {
@Input('data') data:any

}
