

<div class="container containerm p-5" style="text-align:justify">
    <h1 class=" text-center blueColorT display-4 mb">Mentions légales</h1>
    <br>
       <p>
               Les présentes mentions légales et politique de confidentialité définissent et vous informent de la manière dont le Ministère du Travail et de la Fonction Publique du Bénin utilise et protège les informations que vous nous transmettez, le cas échéant, lorsque vous utilisez le présent site accessible à partir de l’URL suivante : www.demarchesmtfp.gouv.bj  (ci-après le « Site »).<br>
       Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout moment par le Ministère du Travail et de la Fonction Publique du Bénin, notamment en vue de se conformer à toute évolution législative, règlementaire, jurisprudentielle ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en tête de la présente politique. Ces modifications engagent l’utilisateur dès leur mise en ligne. Il convient par conséquent que l’utilisateur consulte régulièrement les présentes mentions et politique de confidentialité afin de prendre connaissance de ses éventuelles modifications. <br><br>
       </p>
   <br>

   <h2 class="mb blueColorT text-left">Déclaration à l’Autorité de Protection des Données à caractère Personnel </h2>

   <br>

<p>
Conformément à la loi n° 2017-20 du 20 avril 2018 portant code du numérique en République du Bénin le site a fait l'objet d'une déclaration auprès de l’Autorité de Protection des Données à caractère Personnel (apdp.bj) sous le numéro (xxxxxx) <br>
En tout état de cause, « le Ministère du Travail et de la Fonction Publique du Bénin » ne collecte des informations personnelles relatives à l’utilisateur que pour les besoins de certains services proposés par le site. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Conformément aux dispositions légales en vigueur, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée. Aucune information personnelle de l’utilisateur du site « www.travail-gouv.bj » n’est publiée, cédée ou vendue sur un support quelconque ou à des tiers, à son insu. <br><br>
</p>
<br>
<h2 class=" blueColorT mb"> Confidentialité des données</h2>
<br>
<p>
Vos données personnelles sont confidentielles et ne seront communiquées qu’aux fins pour lesquelles elles ont été collectées et seulement par les entités publiques béninoises et leurs partenaires. Toute communication de vos données personnelles à des tiers n’est faite qu’en cas de nécessité et dans le strict respect de la légalité. <br><br>
</p>

<br>
<h2 class="blueColorT  mb"> Droit d'accès</h2>
<br>

<p>
En application des dispositions de la loi portant code du numérique en République du Bénin, les utilisateurs disposent d’un droit d’accès, de rectification, de modification et de suppression relativement aux données qui les concernent personnellement. Ce droit peut être exercé par le titulaire sur demande adressée par voie postale à (Ministère du Travail et de la Fonction Publique du Bénin) ou par voie électronique à l’adresse email suivante : mtfp.usager@gouv.bj<br><br>
</p>

<br>
<h2 class=" blueColorT mb">Droit de rectification</h2>
<br>

<p>
Conformément à la loi portant code du numérique en République du Bénin, les utilisateurs peuvent demander la rectification, la mise à jour, la suppression, le verrouillage ou encore l’effacement des données les concernant qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes, équivoques ou obsolètes. <br><br>
</p>
<br>

<h2 class="blueColorT mb">Prestataires habilités et transfert vers un pays tiers </h2>
<br>

<p>
le Ministère du Travail et de la Fonction Publique du Bénin vous informe qu’il a recours à ses prestataires habilités pour faciliter le recueil et le traitement des données que vous lui aviez communiqué. Ces prestataires peuvent être situés en dehors du territoire national et ont communication des données recueillies par le biais des divers formulaires présents sur le Site (à l’exception du formulaire vous permettant d’exercer vos droits qui est proposé et exploité par le Ministère du Travail et de la Fonction Publique du Bénin<br>
le Ministère du Travail et de la Fonction Publique du Bénin s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données. Tout particulièrement, la vigilance s’est portée sur l’existence d’un fondement légal pour effectuer un quelconque transfert de données vers un pays tiers. <br>
</p>
<br>

<h2 class=" blueColorT mb">Durée de conservation</h2>
<br>

<p>
Vos données personnelles sont conservées par le Ministère du Travail et de la Fonction Publique du Bénin uniquement pour le temps correspondant à la finalité de la collecte. Toutefois, cette durée de conservation ne saurait excéder vingt-quatre (24) mois. <br><br>
</p>
<br>
<h2 class="blueColorT  mb">Plainte auprès de l’autorité compétente</h2>
<br>

<p>
Si vous considérez que le Ministère du Travail et de la Fonction Publique du Bénin ne respecte pas ses obligations au regard de vos données personnelles collectées, vous pouvez adresser conformément à la procédure légale une plainte ou une demande auprès de l’Autorité de Protection des Données à caractère Personnel. <br><br>
</p>
<br>
<h2 class="  blueColorT  mb">Propriété intellectuelle</h2>
<br>

<p>
Tout le contenu du présent site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive du Ministère du Travail et de la Fonction Publique du Bénin à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs. <br>
Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès écrit le Ministère du Travail et de la Fonction Publique du Bénin. <br><br>
</p>

<br>
<h2 class="  blueColorT  mb">Conditions de service</h2>
<br>

<p>
Ce site est proposé en langages Français, pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs compatibles et modernes comme : Microsoft Edge, Safari, Firefox, Chrome... <br><br>
</p>

<br>
<h2 class=" blueColorT mb">Informations et exclusion</h2>
   <br>

<p>
« le Ministère du Travail et de la Fonction Publique du Bénin» met en œuvre tous les moyens dont il dispose, pour assurer une information fiable et une mise à jour fiable de son site internet. Toutefois, des erreurs ou omissions peuvent survenir. A cet effet, l'internaute pourra envoyer toutes informations auprès de : mtfp.usager@gouv.bj et signaler toutes modifications du site qu'il jugerait utile. <br><br>
</p>
<br>
<h2 class="  blueColorT  mb">Cookies</h2>
<br>

<p>
Pour des besoins de statistiques et d'affichage, le présent site peut utiliser des cookies. Il s'agit de petits fichiers textes stockés sur votre disque dur, qui ne permettent pas l’identification de l’utilisateur, mais qui enregistrent des informations relatives à la navigation d’un ordinateur sur un site. Certaines parties de ce site ne peuvent être fonctionnelle sans l’acceptation de cookies. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation. <br>
Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies : 
<ul>
   <li>Sous Mozilla Firefox : Menu > Options > Onglet "Vie privée" Paramétrer le menu "Règles de conservation" sur "Utiliser les paramètres personnalisés pour l'historique" Enfin, décocher la case "Accepter les cookies tiers"</li>
   <li>Sous Google Chrome : Menu > Paramètres > Afficher les paramètres avancés (situé au bas de la page). Il faut ensuite cliquer sur le bouton Paramètres de contenu puis cocher la case Bloquer les cookies et les données de sites tiers, enfin cliquer sur OK pour valider votre choix</li>
   <li>Sous Safari : Choisissez Safari > Préférences, cliquez sur Confidentialité, puis sélectionnez l’option relative aux « Cookies et données de site web ». Toujours bloquer : Safari ne permet à aucun site web, tiers ni annonceur de stocker des cookies et autres données sur votre Mac. Cela peut empêcher certains sites web de fonctionner correctement. </li>
   <li>Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. </li>
   <li>Sous Internet Explorer : Menu > Options Internet > Onglet "Confidentialité" et sur le bouton Avancé pour faite apparaitre la fenêtre des Paramètres de confidentialité avancés. Cochez ensuite la case "Ignorer la gestion automatique des cookies", puis sélectionner "Refuser" dans la colonne "Cookies tierces parties".</li>
</ul>
<p>
Conformément aux pratiques en la matière, certains cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session, d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et gérés par le Ministère du Travail et de la Fonction Publique du Bénin. <br><br>
</p>
<br>

<h2 class="  blueColorT  mb">Liens hypertextes</h2>
<br>
<p>
Le site « www.demarchesmtfp.gouv.bj » peut offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. Le « Ministère du Travail et de la Fonction Publique du Bénin » ne dispose d'aucun moyen pour contrôler les sites en connexion avec son site internet. Par ailleurs, il ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes. <br>
</p>   
</div>