<section class="o-banner">
    <div class="card text-white rounded-0">
  
        <img src="assets/bg-new.jpeg" class="card-img" height="120" alt="...">
        <div class="card-img-overlay">
  
            <div class="text container">
                <h2 class="card-title text-center">ESPACE POINT FOCAL COMMUNAL</h2>
                <h5 class="text-center">Changement du mot de passe</h5>
                <!--button type="button" class="tbtn">LIRE LA SUITE</button-->
                <p></p>
            </div>
        </div>
    </div>
  
</section>
<div class="container my-4" >

<div class="row ">
    <div class="col-12 col-sm-12 col-md-4"></div>
    <div class="col-12 col-sm-12 col-md-4">
        <div id="loginBlock">
            <div class="card">
                <div class="card-header text-center">
                <strong>Changement de mot de passe</strong>

                </div>
            <div class="card-body">
                <form #pwdForm="ngForm" (ngSubmit)="UpdatePassWord(pwdForm.value)">
                    <div class="form-group">
                        <label for="">Ancien mot de passe </label>
                        <input type="password" class="form-control"  name="last_password" #old_password="ngModel" ngModel>
                        <span *ngIf="old_password.invalid && (old_password.dirty || old_password.touched)" class="text-danger">
                                    <small *ngIf="old_password.errors?.required" id="typeLabelHelp" class="form-text">Mot de passe requis</small>
                                </span>
                    </div>
                    <div class="form-group">
                        <label for="">Mot de passe (Au moins 6 caractères) </label>
                        <input type="password" class="form-control"  name="new_password" #password="ngModel" ngModel>
                        <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                                    <small *ngIf="password.errors?.required" id="typeLabelHelp" class="form-text">Mot de passe requis</small>
                                </span>
                    </div>
                    <div class="form-group">
                        <label for="">Confirmer mot de passe </label>
                        <input type="password" class="form-control"  name="confirm_password" #confirm_password="ngModel" ngModel>
                        <span *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)" class="text-danger">
                                    <small *ngIf="confirm_password.errors?.required" id="typeLabelHelp" class="form-text">Mot de passe requis</small>
                                </span>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-warning"  [disabled]="pwdForm.invalid || loading">Mise à jour Mot de passe</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4"></div> 
</div>

</div>

