<section class="o-banner">
    <div class="card text-white ">

        <img src="assets/bg-new.jpeg" class="card-img" height="420" alt="...">
        <div class="card-img-overlay">

            <div class="text container">
                <h2 class="card-title text-center">PLAN DE LA PLATEFORME</h2>
                <!--button type="button" class="tbtn">LIRE LA SUITE</button-->
                <p></p>
            </div>


        </div>
    </div>

</section>

<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <div class="d-md-flex flex-column rounded p-5 mb-5" style="border: 2px solid #0A3764">
                <h2 class=""></h2>
                <div class="mt-3">
                    <ul class="list-unstyled">
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/prestations-par-structure">Prestations par structure</a></li>
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/prestations-par-thematique">Prestations par thématique</a></li>
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/faq">Allô retraite</a></li>
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/about">A propos</a></li>
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/question">Questions / Suggestions</a></li>
                        <li><i class="fa fa-caret-right">&nbsp;</i>&nbsp;<a routerLink="/je-denonce">Je dénonce</a></li>


                    </ul>
                </div>

                <div class="align-self-center">

                </div>
            </div>
        </div>

    </div>


</div>