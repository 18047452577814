import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertNotif } from '../../alert';
import { PdaService } from '../../core/_services/pda.servic';
import { AuthService } from '../../core/_services/auth.service';

@Component({
  selector: 'app-requete',
  templateUrl: './requete.component.html',
  styleUrls: ['./requete.component.css']
})
export class RequeteComponent implements OnInit {
  loading:boolean=false
  link_to_prestation=1
  selected_type_preoccupation=0
  themes=[]
  services=[]
  onglet_What = false
  mat_aff = false
  is_administrative_officer=1
  selectedEntie=null
  errormessage=""
  selected_data_rvMat: any
  structures=[]
  natures=[]
  institutions = []
  descrCarr=[]
  detailpiece=[]
  closeResult = '';



  constructor(
    private pdaService: PdaService, 
    private user_auth_service: AuthService, 
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.institutions=[]
    this.user_auth_service.getAllInstitu().subscribe((res: any) => {
     this.institutions = res
     })
  }

  onEntiteChange(event){
    this.selectedEntie=+event.target.value
    this.prepare(this.selectedEntie)
  }
  
  prepare(idEntite){
  
    this.structures = []
    this.user_auth_service.getAllServ(1,idEntite).subscribe((res:any)=>{
      this.structures = res
    })
  
   this.natures=[]
    this.user_auth_service.getAllNatu(idEntite).subscribe((res:any)=>{
      this.natures=res
    })
  
    this.themes=[]
    this.user_auth_service.getAllThe(idEntite).subscribe((res:any)=>{
      this.themes=res
    })
  }

  addRequeteusager(value,form:NgForm) {
    
    let service = null
    if (this.link_to_prestation==1 || this.selected_type_preoccupation==0) {
      console.log(this.services)
      service = this.services.filter(e => (e.id == value.idPrestation))[0]
    }else{
      service=this.services.filter(e => (e.hide_for_public == 1))[0]
    }
    // if(service == null){
    //   AlertNotif.finish("Erreur","Aucune prestation (Service Usager) par défaut n'est lié à cet entité", 'error')
    //   return;
    // }
      var param = {
        objet: value.objet,
        idPrestation: this.link_to_prestation==0  ? 0: value.idPrestation,
       // idPrestation: this.link_to_prestation==0  ? service.id : value.idPrestation,
        nbreJours: service == null ? 0 : service.nbreJours,
        msgrequest: value.msgrequest,
        email: value.email,
        idEntite:this.selectedEntie,
        nom:value.lastname+" "+value.firstname,
        tel:value.phone,
        link_to_prestation:this.link_to_prestation,
        interfaceRequete: this.link_to_prestation==1 ? "USAGER"  : "SRU" ,
        plainte: value.plainte,
        matricule: this.is_administrative_officer == 1 ? value.matricule : '',
        visible: 1
      };
      // fichierJoint
        console.log('has_consent',param)
      if(param.idEntite == null || param.idEntite == ""){
        AlertNotif.finish("Erreur","Veuillez choisir une structure destrinatrice.", 'error')
      }else if(param.plainte == null || param.plainte == "0"){
        AlertNotif.finish("Erreur","Veuillez choisir un type de préoccupation.", 'error')
      }else if(this.mat_aff == true && param.matricule.trim() == ''){
        AlertNotif.finish("Renseigner le matricule", "Champ obligatoire", 'error')
      }
     /* else if(param.idPrestation == null || param.idPrestation == ""){
        AlertNotif.finish("Erreur","Veuillez choisir une prestation.", 'error')
      }*/
      else if(!param.objet){
        AlertNotif.finish("Renseigner l'objet", "Champ obligatoire", 'error')
      }else if(!param.msgrequest){
        AlertNotif.finish("Renseigner le message", "Champ obligatoire", 'error')
      }else if(!value.has_consent){
        AlertNotif.finish("Consentement", "Veuillez donner votre consentement", 'error')
      } else{
        this.loading=true
        console.log(param)
        this.pdaService.createrequeteusager(param).subscribe((rest: any) => {
            form.resetForm()
          this.loading=false
          if(rest.status=="error"){
            AlertNotif.finish("Erreur",rest.message, 'error')
          }else{
            AlertNotif.finish("Soumission de préoccupation", "Votre préoccupation a été bien transmise aux autorités compétentes", 'success')
          }
        })
      }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  chargerPrestation(event){
    this.services=[]
    this.user_auth_service.getAllTypePrest(event.target.value).subscribe((res:any)=>{
      this.services=res
    })
    
    this.user_auth_service.getThema(event.target.value).subscribe((res:any)=>{
      this.descrCarr = res.descr
      if(res.libelle== "Formation" || res.libelle == "Carrière"){
        this.mat_aff = true
      }else{
        this.mat_aff = false
      }
    })
    
  }

  
  openDetailModal(event,content){

    this.detailpiece=[]
    this.user_auth_service.getServPiece(event.target.value).subscribe((res:any)=>{
      this.detailpiece=res
    })
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
