<section class="o-banner">
    <div class="card text-white ">

        <img src="assets/bg-new.jpeg" class="card-img" height="420" alt="...">
        <div class="card-img-overlay">

            <div class="text container">
                <h2 class="card-title text-center"> A PROPOS</h2>
                <!--button type="button" class="tbtn">LIRE LA SUITE</button-->
                <p></p>
            </div>


        </div>
    </div>

</section>
<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <div class="d-md-flex flex-column rounded p-5 mb-5" style="border: 2px solid #0A3764">
                <h2 class="">La Direction Générale de la Réforme de l'Etat est l'organe d'exécution de la politique de
                    l'Etat en matière de réformes administrative et institutionnelle.</h2>
                <div class="mt-3">
                        A ce titre, elle est chargée de :
                        <ul>
                            <li>élaborer les normes, procédures et standards de performance ;</li>
                            <li>accompagner et appuyer la mise en œuvre des réformes ;</li>
                            <li>observer et analyser les réformes ;</li>
                            <li>promouvoir l'éthique, la culture de l'excellence et la bonne gouvernance administrative et
                                institutionnelle ; </li>
                            <li>assurer le secrétariat permanent des organes de concertation, de coordination et de gestion
                                des réformes.</li>
                        </ul>
                </div>

               
            </div>
        </div>

    </div>

</div>