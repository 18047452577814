export const VriForm:any={
    name_structure:"",
    type_structure:"",
    department:"",
    municipality_id:"",
    city:"",
    district:"",
    ifu:"",
    ifu_file:{
        file:"",
        name:"IFU",
        file64:"",
        isSetted:false

    },
    rccm:"",
    rccm_file:{
        file:"",
        name:"RCCM",
        file64:"",
        isSetted:false

    },
    has_delegate:0,
    delegate_file:{
        file:"",
        name:"Avis du ou des délégués du personnel (Version PDF)",
        file64:"",
        isSetted:false

    },
    identity:"",
    job:"",
    phone:"",
    email:"",
    ri_file:{
        file:"",
        name:"Règlement intérieur",
        file64:"",
        isSetted:false

    },
    doc_file:{
        file:"",
        name:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
        file64:"",
        isSetted:false

    },
}