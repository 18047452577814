export const VcnForm:any={
    code:"",
    has_account:"",
    type:"",
    identity_requester:"",
    email_requester:"",
    contact_requester:"",
    quality_requester:"",
    npi_requester:"",

    identity_respo:"",
    email_respo:"",
    contact_respo:"",
    job_respo:"",
    city_respo:"",
    department_respo:"",
    municipality_respo:"",
    npi_respo:"",
    requester_is_respo:false,


    name_structure:"",
    type_structure:"",
    type_structure_id:"",
    email_structure:"",
    contact_structure:"",
    city_structure:"",
    district_structure:"",
    department_structure:"",
    municipality_structure:"",
    ifu_structure:"",
    doc_file:{
        file:"",
        name:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
        file64:"",
        isSetted:false

    },
    list:[]
}