<div class="container py-3">
    <h2 class="text-left"><strong>Emplacement de nos centres communaux</strong></h2>
    <div class="blueColor mb-2" style="height: 10px; width: 20%;"></div>
    <div class="mb-3">
        <p>
            Vous ne disposez pas de connexion chez vous ou vous n’avez pas de terminal pour accéder aux services de l’administration, rapprochez-vous du centre communal de service public ou du guichet de service des relations avec les usagers proche de votre résidence pour bénéficier de l’appui des points focaux. Ces deniers vont vous assister dans : 
        </p>
        <ul>
            <li>L’utilisation de la borne tactile connectée sur laquelle vous pouvez accéder à l’ensemble des prestations dématérialisées de l’administration publique ;</li>
            <li>L’enregistrement d’une préoccupation à l’endroit de l’administration publique ;</li>
            <li>La recherche d’information sur les services dématérialisés ou sur une prestation de l’administration publique béninoise. </li>
        </ul>
    </div>
</div>
<div class="container-fluid">
    <agm-map [zoom]="15" [latitude]="lat" [longitude]="lng">
        <agm-marker *ngFor="let d of data" [latitude]="d.lat" [longitude]="d.lng"></agm-marker>
    </agm-map>
</div>
