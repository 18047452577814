
<section class="bg-body-tertiary">
    <div class="container p-5">
        <div class="mb-5">
            <h1 class="mb-2 meb blueColorT" style=" text-align: center;">PORTAIL DES DEMARCHES ADMINISTRATIVES</h1>
            <marquee behavior="scroll" direction="horizontal">{{settings?.header_text}}</marquee>
           </div>
           <div class="row">
               <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                   <p class="text-center h2 pda-text-success meb">{{totalInfo + totalPlainte}}</p>  
                   <h6 class="text-center blueColorT msb">Préoccupations adressées au MTFP</h6>
               </div>
               <!-- <div class="col-12 col-md-4 d-flex flex-column justify-content-between">
                   <p class="text-center h2 pda-text-yellow meb">{{totalPrestation}}</p>  
                   <h6 class="text-center blueColorT msb">Prestations dématérialisées</h6>
               </div> -->
               <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                   <p class="text-center h2 pda-text-danger meb">{{totalPrestation}}</p>  
                   <h6 class="text-center blueColorT msb">Prestations du MTFP disponibles</h6>
               </div>
           </div>
    </div>
     
</section>
<section class="bg-white container p-3 mb-5">
    <div class="mb-5">
        <h2 class="text-left blueColorT msb">Nos services en ligne</h2>
        <div class="pda-bg-yellow mb-2" style="height: 10px; width: 15%;"></div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let d of eservices" >
                    <app-prestation-item [data]="d"></app-prestation-item>
                    <!-- <div class="card shadow mb-5 p-1 rounded card_eservices">
                        <div class="card-body">
                            <div class="d-flex ">
                                <div class="align-self-center text-center text-sm-start">
                                    <i class="fas fa-address-card fa-3x blueColorT"></i> 
                                </div>
                                <div class="p-2 ">
                                    <h6 class=""> <strong><small>{{d.title}}</small></strong></h6>
                                    <div class="block-sollicitation-title d-flex ">
                                        <a *ngIf="d.is_external_service" type="button" target="_blank" href="{{d.link}}" class="blueColorT ">Faire une demande</a>
                                        <a *ngIf="!d.is_external_service" type="button" routerLink="/e-services/{{d.code}}" class="blueColorT ">Faire une demande</a>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div> -->
    
                </ng-template>
            </owl-carousel-o>
    </div>
    <div class="col-12 col-md-3">
        <div class="card h-100">
            <img src="assets/ccsp2.jpg" class="card-img-top" style="height: 180px;">
            <div class="card-body d-flex flex-column justify-content-around">
              <h5 class="card-title text-center msb">VOS POINTS DE CONTACT</h5>
              <p class="card-text">Vos points de contact sur toute l'étendue du territoire national</p>
              <a routerLink="/ccsps" class="btn btn-primary btn-sm blueColor text-white">Accéder</a>
            </div>
          </div>
       
    </div>
    <div class="col-12 col-md-3">
        <div class="card h-100">
            <img src="assets/catalogue.avif" class="card-img-top" style="height: 180px;">
            <div class="card-body d-flex flex-column justify-content-around">
              <h5 class="card-title text-center msb">CATALOGUE DES PRESTATIONS</h5>
              <p class="card-text">Consultez la liste exhaustive de nos prestations</p>
              <a routerLink="/prestations-par-thematique" class="btn btn-primary btn-sm blueColor text-white">Accéder</a>
            </div>
          </div>
    </div>
    </div>
</section>
<section class="bg-body-tertiary p-3 mb-5">
    <div class="container">
        <div class="mb-5">
            <h2 class="text-left blueColorT msb">Autres plateformes de service</h2>
            <div class="pda-bg-yellow mb-2" style="height: 10px; width: 15%;"></div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <div class="card py-3 pda-bg-success" style="height: 15rem;">
                    <div class="text-center">
                        <img src="assets/user.jpg" height="60" width="60" alt="" class="text-info">
                    </div>
                    <div class="card-body d-flex flex-column justify-content-around">
                      <h5 class="card-title text-center msb "> <a routerLink="/logusager"  class="blueColorT text-decoration-none text-white">ESPACE USAGER</a></h5>
                      <p class="card-text text-center text-white">Soumettre mes préoccupations à l'administration.</p>
                    </div>
                  </div>
             
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="card py-3 " style="height: 15rem; background-color: orange;">
                    <div class="text-center">
                        <img src="assets/guv.svg" height="60" width="60" alt="" class="text-info">
                    </div>
                    <div class="card-body d-flex flex-column justify-content-around">
                      <h5 class="card-title text-center msb"> <a routerLink="/logusager"  class="blueColorT text-white text-decoration-none">GUICHET UNIQUE VIRTUEL</a></h5>
                      <p class="card-text text-center text-white">Accédez à l'ensemble de nos plateformes usagers à partir d'un seul compte.</p>
                    </div>
                  </div>
               
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="card py-3 bg-primary" style="height: 15rem;">
                    <div class="text-center">
                        <img src="assets/career.avif" height="60" width="60" alt="" class="text-info">
                    </div>
                    <div class="card-body d-flex flex-column justify-content-around">
                      <h5 class="card-title text-center msb"> <a href="https://weche.fpbenin.net/" target="_blank"  class="blueColorT text-white text-decoration-none">WECHE</a></h5>
                      <p class="card-text text-center text-white">Connaître ma situation administrative.</p>
                    </div>
                  </div>
            
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="card py-3" style="height: 15rem; background-color: orangered; ">
                    <div class="text-center">
                        <img src="assets/denonciation.jpg" height="60" width="60" alt="" class="text-info">
                    </div>
                    <div class="card-body d-flex flex-column justify-content-around">
                      <h5 class="card-title text-center msb"> <a routerLink="/je-denonce"  class="text-white text-decoration-none">JE DENONCE UNE DERIVE</a></h5>
                      <p class="card-text text-center text-white">Signaler la dérive d'un agent.</p>
                    </div>
                  </div>
               
            </div>
        </div>
    </div>
</section>

<section class="bg-white">
    <div class="container">
        <div class="mb-5">
            <h2 class="text-left blueColorT msb">Liens utiles</h2>
            <div class="pda-bg-yellow mb-2" style="height: 10px; width: 15%;"></div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card shadow mb-3  rounded">
                    <div class="card-body" title="Ministere du Travail et de la Fonction Publique">
                        <div class="d-flex flex-column justify-content-around">
                            <div class="text-center mb-3">
                                <a href="https://travail.gouv.bj" target="_blank" class="text-dark text-decoration-none ">
                                    <img src="assets/logo-mtfp.png" class="img-fluid h100 mx-auto" style="height:60px" alt="">
                                </a>
                            </div>
                            <h6 class="text-center">MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE</h6>

                        </div>
    
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card shadow  mb-3  rounded">
                    <div class="card-body" title="CNSS BENIN">
                        <div class="d-flex flex-column justify-content-around">
                            <div class="text-center mb-3">
                                <a href="https://cnssbenin.org" target="_blank" class="text-dark text-decoration-none mb-3">
                                    <img src="assets/logo-cnss.png" class="img-fluid  mx-auto" style="height:60px"
                                        alt="">
                                </a>
                            </div>
                            <h6 class="text-center">CAISSE NATIONALE DE SECURITE SOCIALE</h6>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<ng-template #detailPresta let-modal>
    <form #frmrdv="ngForm"  class="form-horizontal" novalidate="" *ngFor="let depi of detailpiece">
        <div class="modal-header bg-info">
            <h4 class="modal-title text-white" id="modal-basic-title">Informations sur la prestation</h4>
            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
        </div>
        <div class="modal-body">
            <label > Prestation : </label>
            <div class="form-group row" >
                <label class="col-sm-10" style="padding-right:0px;" > {{ depi.libelle }} </label>
            </div><br/>
            <div class="form-group row" >
                <label class="col-sm-3" > Structure : </label>
                <div class="col-sm-9" >{{ depi.service_parent.libelle!=null ? depi.service_parent.libelle : 'Aucune structure'}}</div>
            </div><br/>
            <div class="form-group row" >
                <label for="objet" class="col-sm-2">Délai : </label>
                <div class="col-sm-10" >{{ depi.delai }}</div>
            </div><br/>
            <label for="objet" class="col-sm-12">Liste des pièces : </label>
            <ul class="col-sm-12">
            <li *ngFor="let piece of depi.listepieces ; index as y " style="margin-bottom: 10px; list-style: none;">{{ y+1}}. {{ piece.libellePiece }} </li>
            </ul>
        </div>
    </form>
</ng-template>

<ng-template #detailOffice let-modal>
        <div class="modal-header blueColor">
            <h4 class="modal-title text-white" id="modal-basic-title">Connaître ma dernière situation</h4>
            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
        </div>
        <div class="modal-body">
           <div class="form-group">
            <label for="">Matricule</label>
            <input type="text" class="form-control">
           </div>
           <div class="form-group">
            <label for="">Date de prise de service</label>
            <input type="date" class="form-control">
           </div>
           <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="need_notif=!need_notif">
            <label class="form-check-label" for="flexCheckDefault">
              Voulez-vous recevoir ces informations dans votre boîte mail ?
            </label>
          </div>
          <div class="form-group" *ngIf="need_notif">
            <label for="">Email</label>
            <input type="email" class="form-control">
           </div>
           <div class="text-end">
            <button class="btn blueColor text-white">Vérifier</button>
           </div>
        </div>
</ng-template>