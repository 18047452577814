export const DamForm:any={
    identity:"",
    phone:"",
    email:"",
    cip:"",
    ifu:"",
    ifu_file:{
        file:"",
        name:"IFU",
        file64:"",
        isSetted:false

    },
    an_file:{
        file:"",
        name:"Acte de naissance légalisé",
        file64:"",
        isSetted:false

    },
    certificat_file:{
        file:"",
        name:"Certificat ou diplôme d'étude spécialisé en santé au travail légalisé",
        file64:"",
        isSetted:false

    },

    authenticite_file:{
        file:"",
        name:"Attestation d'authenticité des diplômes obtenus à l'étranger s'il y a lieu",
        file64:"",
        isSetted:false

    },

    private_file:{
        file:"",
        name:"Autorisation d'exercice en clientèle privée",
        file64:"",
        isSetted:false

    },
  
}